.embedFail {
	--border-radius: var(--radius-containers);
	--vertical-spacing: var(--spacing-xl);

	align-items: center;
	background-color: var(--background);
	border-radius: var(--border-radius);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xs);
	justify-content: center;
	margin-top: var(--vertical-spacing);
	padding: var(--spacing-3xl) var(--spacing-s);
	text-align: center;

	& a {
		color: var(--btn-primary-background);
		text-decoration: underline;
	}

	& svg {
		color: var(--color-neutral-dark-40);
	}
}

.embedFailParagraph {
	--font-family: var(--typography-body-family);
	--font-size: var(--typography-body-s-size);
	--font-weight: 400;
	--line-height: 1.54;
}

.embedFailTitle {
	--font-family: var(--typography-h-s-family);
	--font-size: var(--typography-h-s-size);
	--font-weight: var(--typography-h-s-weight);
	--letter-spacing: var(--typography-h-s-letter-spacing);
	--line-height: var(--typography-h-s-line-height);
}
